@import '../../node_modules/flickity/dist/flickity.min.css';

.image-carousel {
  grid-column: 2 / 26;
  display: grid;
  grid-template-columns: repeat(11, 1fr var(--gap)) 1fr minmax(25px, 1fr);
  overflow: hidden;
}
.image-carousel .carousel {
  grid-column: 1 / 25;
  overflow: hidden;
}
.image-carousel .flickity-viewport {
  overflow: visible;
}
.image-carousel img {
  display: block;
  height: calc(calc(100vw - 50px) * 0.6);
  margin-right: 25px;
}
.image-carousel .buttons {
  display: flex;
  grid-column-end: 24;
  margin-top: 11px;
}
.image-carousel button {
  width: 30px;
  padding: 0;
  background: 0;
  border: 0;
  cursor: pointer;
  transition: opacity 0.2s;
}
.image-carousel button:first-child {
  margin-right: 15px;
}
.image-carousel button.hidden {
  pointer-events: none;
  opacity: 0;
}
.image-carousel svg {
  overflow: visible;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .image-carousel {
    grid-column-start: 2;
    grid-column-end: 26;
  }
  .image-carousel .carousel {
    overflow: hidden;
  }
  .image-carousel img {
    height: 450px;
    margin-right: 20px;
  }
  .image-carousel .buttons {
    margin-top: 22px;
  }
  .image-carousel button:first-child {
    margin-right: 25px;
  }
  .image-carousel button {
    width: 50px;
  }
}

@media screen and (min-width: 950px) {
  .image-carousel {
    grid-column: 6 / 26;
    display: grid;
    grid-template-columns: repeat(9, 1fr var(--gap)) 1fr 1fr;
  }
  .image-carousel .carousel {
    grid-column: 1 / 21;
  }
  .image-carousel .buttons {
    grid-column: 17 / 18;
    justify-self: end;
  }
}

@media screen and (min-width: 1100px) {
  .image-carousel img {
    height: 500px;
  }
}

@media screen and (min-width: 1500px) {
  .image-carousel {
    grid-template-columns: repeat(9, var(--max-column-width) var(--gap)) var(--max-column-width) 1fr;
  }
  .image-carousel img {
    height: 650px;
  }
}
