/* Text blocks */
section.small-text p:first-child {
  margin-top: 0;
}
section.small-text p:last-child {
  margin-bottom: 0;
}

section.large-text {
  font-size: 28px;
  letter-spacing: 0.01em;
}
section.large-text p:first-child {
  margin-top: -0.20em;
}
section.large-text p:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 768px) {
  section.large-text {
    font-size: 44px;
    line-height: 1.31;
  }
}

@media screen and (min-width: 950px) {
  section.large-text {
    grid-column: 6 / 23;
  }
  section.small-text {
    grid-column-start: 14;
    grid-column-end: 25;
  }
}
