.split-images figure + figure {
  margin-top: 45px;
}

@media screen and (min-width: 500px) {
  .split-images figure img {
    max-height: 80vh;
  }
}

@media screen and (min-width: 950px) {
  .split-images figure + figure {
    margin-top: 0;
  }
  .split-images {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-column-gap: var(--gap);
  }
  .split-images .image-1 {
    grid-column-start: 1;
    grid-column-end: 2;
  }
  .split-images .image-2 {
    grid-column-start: 2;
    grid-column-end: 3;
  }
}
