@import './css/normalize.css';
@import './css/type.css';
@import './css/tooltip.css';
@import './css/text-block.css';
@import './css/video-block.css';
@import './css/split-images-block.css';
@import './css/carousel-block.css';

/* Hide focus outlines for mouse users */
[data-whatintent='mouse'] *:focus {
  outline: none;
}

:root {
  --gap: 21px;
  /* We'll use this at higher window widths */
  --max-column-width: 106px;
  --columns: minmax(25px, 1fr) repeat(11, 1fr var(--gap)) 1fr minmax(25px, 1fr);
}
body {
  overflow-x: hidden;
  font-family: Helvetica Neue, HelveticaNeue, HelveticaNeueStd, Helvetica, Arial, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, sans-serif;;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  margin-top: 25px;
}
main {
  position: relative;
}

/* Site header */
.site-header {
  margin-bottom: 70px;
  display: grid;
  grid-template-columns: var(--columns);
}
.site-header h1 a {
  text-decoration: none;
}
h1 {
  margin-top: 0;
  grid-column: 2 / 25;
}
.site-header .description {
  grid-column: 2 / 25;
}
footer {
  text-align: center;
  margin-bottom: 70px;
}
footer p {
  margin: 0;
  display: none;
}

@media screen and (min-width: 500px) {
  main {
    margin: 0 auto;
  }
  header {
    display: grid;
    grid-template-columns: var(--columns);
  }
  h1 {
    grid-column-start: 2;
    grid-column-end: 5;
  }
  .site-header .description {
    grid-column: 10 / 25;
  }
  footer {
    margin-bottom: 112px;
    margin-top: -20px;
  }
}
@media screen and (min-width: 768px) {
  :root {
    --columns: 50px repeat(11, 1fr var(--gap)) 1fr 50px;
  }
  .site-header .description {
    grid-column: 16 / 25;
  }
}

/* Posts */
article {
  display: grid;
  width: 100%;
  grid-template-columns: var(--columns);
}
article header {
  display: grid;
  grid-template-columns: inherit;
  margin-bottom: 40px;
  grid-column: 1 / 26;
  grid-row-start: 1;
  position: sticky;
  background: white;
  top: 0;
  padding-top: 12px;
  padding-bottom: 12px;
  z-index: 100;
  box-sizing: border-box;
  align-self: start;
}
article header > div {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  grid-column: 2 / 25;
}
h2 {
  margin-top: 0;
  margin-bottom: 0;
  white-space: pre-line;
}
article section {
  grid-column: 2 / 25;
  margin-bottom: 42px;
}

@media screen and (min-width: 950px) {
  h2 {
    margin-bottom: 10px;
  }
  article header {
    display: block;
    grid-column: 2 / 4;
    grid-row: 1 / 100;
    top: 25px;
  }
  article header > div {
    display: block;
  }
  article section {
    grid-column-start: 6;
    grid-column-end: 25;
  }
}

@media screen and (min-width: 1100px) {
  article section {
    margin-bottom: 130px;
  }
}

@media screen and (min-width: 1600px) {
  :root {
    --gap: 21px;
    --columns: minmax(50px, 1fr) repeat(11, var(--max-column-width) var(--gap)) var(--max-column-width) minmax(50px, 1fr);
  }
}

/* Image/video blocks */
section figure {
  padding: 0;
  margin: 0;
}
section figure img {
  max-width: 100%;
  display: block;
}

figcaption p:last-child {
  margin-bottom: 0;
}

section.large-image,
section.large-video {
  display: grid;
  grid: inherit;
  grid-column-start: 1;
  grid-column-end: 26;
}

section.large-image figure,
section.large-video figure {
  display: grid;
  grid: inherit;
  grid-column-start: 1;
  grid-column-end: 26;
}

section.large-image img,
section.large-video .player {
  grid-column-start: 1;
  grid-column-end: 26;
}

section.large-image figcaption,
section.large-video figcaption {
  grid-column-start: 2;
  grid-column-end: 25;
}

@media screen and (min-width: 950px) {
  section.large-image,
  section.large-video {
    display: block;
    grid-column-start: 6;
    grid-column-end: 25;
  }
  
  section.large-image figure,
  section.large-video figure {
    display: block;
  }  
}