body {
  font-size: 18px;
  font-family: Helvetica Neue, HelveticaNeue, HelveticaNeueStd, Helvetica, Arial, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, sans-serif;
  font-weight: normal;
  line-height: 1.363;
  letter-spacing: 0.02em;
}

h1, h2 {
  font-size: 35px;
  font-weight: normal;
  line-height: 0.914;
  letter-spacing: 0.03em;
}

a {
  color: inherit;
}


@media screen and (min-width: 1200px) {
  html body {
    font-size: 22px;
  }
  h1, h2 {
    font-size: 44px;
  }
}
