.tooltip {
  position: relative;
  cursor: pointer;
  display: inline-block;
}
.tooltip > span {
  text-decoration: underline;
}
.tooltip .content {
  display: none;
  min-width: 205px;
  position: absolute;
  top: 100%;
  left: 0;
  background: black;
  color: white;
  z-index: 101;
}
.tooltip:hover > span {
  text-decoration: none;
}
.tooltip:hover .content {
  display: block;
}
.tooltip ul {
  list-style: none;
  padding-left: 0;
  margin: 10px 14px 12px 16px;
  line-height: 1.7;
}
.tooltip a {
  text-decoration: none;
}
.tooltip.share-button {
  display: inline-block;
}

article header .tooltip .content {
  left: auto;
  right: 0;
}

@media screen and (min-width: 768px) {
  .tooltip .content {
    font-size: 16px;
  }
}

@media screen and (min-width: 950px) {
  article header .tooltip .content {
    left: 0;
    right: auto;
  }
}
