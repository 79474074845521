/* Video block */
.video-block video {
  display: block;
  max-width: 100%;
}

.video-block .player {
  position: relative;
  cursor: pointer;
}

.video-block button {
  width: 70px;
  height: 70px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  filter: drop-shadow(0 1px 4px rgba(0,0,0,0.1));
  transition: opacity 0.4s;
  user-select: none;
}

.video-block button.hidden {
  opacity: 0;
  pointer-events: none;
}

.video-block button svg {
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 950px) {
  .video-block button {
    width: 107px;
    height: 107px;
  }
}
